// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDSfQEDKQl1uIQlgvu7qJ3LGzW6W2ZArto",
  authDomain: "gage-admin.firebaseapp.com",
  projectId: "gage-admin",
  storageBucket: "gage-admin.appspot.com",
  messagingSenderId: "543439972452",
  appId: "1:543439972452:web:59305a7a68a300626e1771"

  // apiKey: "AIzaSyD6ez_DUaBg8bS8eHFFQym7MtSxcTfOjEA",
  // authDomain: "gage-merge.firebaseapp.com",
  // projectId: "gage-merge",
  // storageBucket: "gage-merge.appspot.com",
  // messagingSenderId: "925787471490",
  // appId: "1:925787471490:web:c6ae2d9aa0643172ef3e9b"
  // apiKey: "AIzaSyD8EKVPyWa9lp_6ho_isk1s8dzV3pfrE3c",
  // authDomain: "eventquestionsv2.firebaseapp.com",
  // projectId: "eventquestionsv2",
  // storageBucket: "eventquestionsv2.appspot.com",
  // messagingSenderId: "981987305222",
  // appId: "1:981987305222:web:a0f287fde5251ce25f6087",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
// const admin= firebase.firestore();
const auth = getAuth(app);

export { db, storage, auth };
